import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js'

// Used to show a tooltip for controller element
// Set "tip" with tipValue
// data: { controller: 'tooltip', "tooltip-tip-value" => "I'm a tip!" }
export default class extends Controller {
  static values = { tip: String }

  initialize() {
    let el = this.element
    tippy(el, { content: this.tipValue })
  }

}
