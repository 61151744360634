import Flatpickr from "stimulus-flatpickr";

export default class extends Flatpickr {

  // Set enableTimeValue to add time picker ...
  // data: { controller: 'flatpickr', "flatpickr-enable-time-value" => true }
  static values = { enableTime: Boolean }

  initialize() {
    // console.info("Flatpickr init")
    this._altFormat = this.enableTimeValue ? "m/d/Y h:i K" : "m/d/Y"
  }

  connect() {
    // console.info("Flatpickr connected")
    this.setConfig()
    super.connect() // always call super.connect()
    this.addTypingListener()
  }

  setConfig() {
     this.config = {
       altFormat: this._altFormat,
       altInput: true,
       allowInput: true,
       allowInvalidPreload: true, // allow invalid dates during typing
       enableTime: this.enableTimeValue,
       errorHandler: function(error, event) {
         this._error = true
         console.info("error", error)
         console.info("event", event)
       }
     }
  }

  // If user begins typing, close the picker and allow full manual entry;
  // otherwise, there are too many ways the two methods can interfere
  addTypingListener() {
    let picker = this.fp
    picker._input.addEventListener("keyup", function(event) {
      picker.close()
    })
  }

}
