import { Application } from "@hotwired/stimulus"
const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug = false
window.Stimulus = application

export { application }


// Stimulus controllers

import FlatpickrController from "./flatpickr_controller"
application.register("flatpickr", FlatpickrController)

import SelectController from "./select_controller"
application.register("select", SelectController)

import TooltipController from "./tooltip_controller"
application.register("tooltip", TooltipController)

import TimestampController from "./timestamp_controller"
application.register("timestamp", TimestampController)
