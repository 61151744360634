import { Controller } from "@hotwired/stimulus"

// Used to embed a timestamp when field is focused
export default class extends Controller {

  stamp() {
    let s = this.getTimestamp()    
    this.element.value = s + " -- \n\n" + this.element.value
    // Focus cursor at (26, 26) after timestamp
    this.element.setSelectionRange(26, 26)
  }

  getTimestamp() {
    return new Date().toLocaleDateString('en-us', this.dateFormat())
  }

  dateFormat() {
    return {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit"
    }
  }

}
